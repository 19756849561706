"use client";
import { isLinkActive, linkUrl } from "@/helpers";
import { usePathname } from "next/navigation";
import DPIcon from "../DPIcon/DPIcon";
import { formatUrl } from "@/helpers/formatters";
import Image from "next/image";
import FastMenu, { LinkType } from "../FastMenu/FastMenu";
import _e from "@/helpers/texts";
import LoadingLink from "../LoadingLink/LoadingLink";

export default function SideBar({ list, hideSubs, className, hideFastMenu }: {
  readonly list: LinkType[],
  readonly hideSubs?: boolean,
  readonly className?: string,
  readonly hideFastMenu?: boolean,
}) {
  const pathname = usePathname();

  list.forEach((item: Record<string,any>) => {
    item.url = formatUrl(item.url);
    item.sublist = item?.sublink?.length ? item.sublink.map((sub: Record<string,any>) => formatUrl(sub.url)) : undefined;
  });

  let hasIcon = false;
  list.forEach((item: Record<string,any>) => {
    if(item?.icon || item?.icon_url) hasIcon = true;
  });

  return (
    <>
      <nav className={className ?? ''}>
        <ul className="list-none">
          {
            list?.map((item: Record<string,any>) => (
              <li key={item.id} className={`py-[15px] ps-[20px] pe-[10px] pb-5 text-[#949ba5] font-medium transition-all duration-300 cursor-pointer ${hasIcon ? 'text-[13px] border-t-[1px] [&:first-child]:border-t-0 border-slate-100' : 'text-sm'}`}>
                <LoadingLink key={item.id} href={`${formatUrl(item.url, true)}`}
                  target={item?.target === '_blank' ? '_blank' : '_self'}
                  rel={item?.target === '_blank' ? 'noopener noreferrer' : ''}
                  className={`hover:text-lava flex items-center justify-between group transition-all ${isLinkActive(pathname, formatUrl(item.url, true), (item?.exact ? '' : 'text-lava'), 'text-lava', item.sublist)}`}
                >
                  {
                    (() => {
                      return hasIcon ? (
                        <span className="flex items-center">
                          {
                            (() => {
                              return item?.icon ? (
                                <DPIcon icon={item.icon} className={`text-2xl w-8 grayscale ${isLinkActive(pathname, formatUrl(item.url, true), '!grayscale-0', '!grayscale-0', item.sublist)} group-hover:grayscale-0 transition-all`} />
                              ) : (
                                <div className="w-8 h-5 relative">
                                  <Image
                                    src={linkUrl(process.env.NEXT_PUBLIC_ASSET_ROOT + '/assets/images/', item?.icon_url ?? '') ?? ''}
                                    fill={true}
                                    sizes="100%"
                                    alt={item?.caption ?? 'Doping Hafıza'}
                                    style={{ objectFit: 'contain', objectPosition: 'left center'}}
                                    className={`grayscale ${isLinkActive(pathname, formatUrl(item.url, true), '!grayscale-0', '!grayscale-0', item.sublist)} group-hover:grayscale-0 transition-all`}
                                  />
                                </div>
                              );
                            })()
                          }
                          <span>{ item?.caption }</span>
                        </span>
                      ) : (
                        <span>{ item?.caption }</span>
                      );
                    })()
                  }
                  {
                    (() => {
                      if(item.sublist && !hideSubs) {
                        let arrow = isLinkActive(pathname, formatUrl(item.url, true), 'arrow-down', 'arrow-down', item.sublist);
                        if(!arrow) arrow = 'arrow-right';
                        return (<DPIcon icon={`${arrow}`} className="text-2xl" />);
                      }
                    })()
                  }
                </LoadingLink>
                {
                  (() => {
                    if(item?.sublink?.length > 0 && !hideSubs) {
                      return (
                        <div className={`${hasIcon ? 'ps-8 pe-[15px]' : 'px-[15px]'} hidden ${isLinkActive(pathname, formatUrl(item.url, true), '!block', '!block', item.sublist)}`}>
                          {
                            item?.sublink?.map((sub: Record<string,any>) => (
                              <div key={item.id + '_' + sub.id} className={`pt-[20px] ${isLinkActive(pathname, formatUrl(sub.url, true), 'text-lava', 'text-lava')}`}>
                                <LoadingLink href={`${formatUrl(sub.url, true)}`}
                                  target={item?.target === '_blank' ? '_blank' : '_self'}
                                  rel={item?.target === '_blank' ? 'noopener noreferrer' : ''}
                                  className="hover:text-lava transition-all"
                                >
                                  { sub?.caption }
                                </LoadingLink>
                              </div>
                            ))
                          }
                        </div>
                      );
                    }
                  })()
                }
              </li>
            ))
          }
        </ul>
      </nav>
      { !hideFastMenu && (<FastMenu label={_e('Hızlı Erişim Menüsü')} links={list} />) }
    </>
  );
}
