"use client";

import { isLinkActive } from "@/helpers";
import _e from "@/helpers/texts";
import { usePathname } from "next/navigation";
import { useEffect, useRef, useState } from "react";
import DPIcon from "../DPIcon/DPIcon";
import { formatUrl } from "@/helpers/formatters";
import LoadingLink from "../LoadingLink/LoadingLink";

export type LinkType = {
  id: number|string,
  url: string,
  caption: string,
  active?: boolean,
  related?: boolean,
  target?: string,
  exact?: boolean,
  sublink?: LinkType[],
  sublist?: string[],
};
export default function FastMenu({ label, links }: {
  readonly label?: string,
  readonly links: LinkType[],
}) {
  const [ toggleState, setToggleState ] = useState(false);
  const [ winHeight, setWinHeight ] = useState<number>(0);

  const pathname = usePathname();
  
  const menuContainerRef = useRef<HTMLDivElement>(null);
  
  const handleToggle = () => {
    setToggleState((current: boolean) => !current);
    handleResize();
  };

  links.forEach((item: Record<string,any>) => {
    item.url = formatUrl(item.url);
    item.sublist = item?.sublink?.length ? item.sublink.map((sub: Record<string,any>) => formatUrl(sub.url)) : undefined;
    item.active = isLinkActive(pathname, formatUrl(item.url, true), item?.exact ? '' : 'active', 'active', item.sublist) === 'active';
  });

  const handleResize = () => {
    setWinHeight(window?.innerHeight ?? 0);
    if(toggleState && window?.innerHeight) {
      menuContainerRef.current?.style?.setProperty('height', (winHeight - 40) + 'px');
    } else {
      menuContainerRef.current?.removeAttribute('style');
    }
  };
  
  useEffect(() => {
    handleResize();

    window?.addEventListener('resize', handleResize);
    return () => {
      window?.removeEventListener('resize', handleResize);
    };
  });

  return (
    <nav ref={menuContainerRef} className={`block lg:hidden fixed left-0 right-0 bottom-0 transition-all duration-200 w-full bg-white ${toggleState ? 'h-[calc(100lvh-100px)] pt-16 overflow-auto custom-scroll' : '!h-[82px] overflow-hidden'} shadow-2xl z-[98] py-3 px-5`} data-footer-fast-menu data-wa-bottom="72">
      <div className="border rounded-lg border-gray-400 pt-1 pb-2 flex items-center justify-between" onClick={handleToggle}>
        <div className="flex-grow-1">
          <label className="text-xs px-[20px] text-slate-400 font-medium leading-tight">{ label ?? _e('Hızlı Erişim Menüsü') }</label>
          <div className="text-sm px-[20px] text-charcoal">{ links.find((item) => item.active)?.caption ?? '' }</div>
        </div>
        <div className={`flex items-center justify-center ms-2 me-[20px] mt-1 flex-grow-0 flex-shrink-0 cursor-pointer rounded-full ${toggleState ? 'bg-charcoal text-white p-2 translate-x-2' : 'bg-transparent text-slate-500'}`}>
          <DPIcon icon={ toggleState ? 'close' : 'direction' } className={`${toggleState ? 'text-base' : 'text-2xl'}`} />
        </div>
      </div>

      <div className="relative mt-5">
        <ul className="list-none">
          {
            links?.map((item: Record<string,any>) => (
              <li key={item.id} className={`py-[15px] ps-[20px] pe-[10px] pb-5 text-[#949ba5] text-sm font-medium transition-all duration-300 cursor-pointer border-b-[1px] border-slate-100`}>
                <LoadingLink key={item.id} href={`${formatUrl(item.url, true)}`}
                  target={item?.target === '_blank' ? '_blank' : '_self'}
                  rel={item?.target === '_blank' ? 'noopener noreferrer' : ''}
                  className={`hover:text-lava flex items-center justify-between ${item.active ? 'text-lava' : ''}`}
                >
                  <span>{ item?.caption }</span>
                </LoadingLink>
              </li>
            ))
          }
        </ul>
      </div>
    </nav>
  );
}
