"use client";

import Image from "next/image";
import DPIcon from "../DPIcon/DPIcon";
import React, { useEffect, useRef, useState } from "react";
import { formatENetValues, formatNumber, formatPackageEventItem, formatUrl, getECommerceId, insertENetValues } from "@/helpers/formatters";
import _e from "@/helpers/texts";
import { addToCart } from "../CartLink/CartLink";
import { useRouter } from "next/navigation";
import { gtmSender, linkUrl, stripTags } from "@/helpers";
import { productDynamicValues } from "@/helpers/fetchers/constants";
import { durationMinutes } from "@/helpers/fetchers/package";
import JsonLd from "../JsonLd/JsonLd";
import LoadingLink from "../LoadingLink/LoadingLink";
import BuyInfoDialog from "../BuyInfoDialog/BuyInfoDialog";
export default function PackageUsageInfo({
  data,
  packagesSlug
}: {
  readonly data: Record<string, any>;
  readonly packagesSlug: string;
}) {
  const dialogRef = useRef<any>(null);

  const router = useRouter();
  const [ highlights, setHighlights ] = useState([] as { id: number, url: string, caption: string }[]);

  const handleProductClick = () => {
    gtmSender({ ecommerce: null });
    gtmSender({
      event: "select_item",
      ecommerce: {
        items: [ formatPackageEventItem(data) ],
      },
    });
  };

  useEffect(() => {
    const highlightList: { id: number, url: string, caption: string }[] = [];
    const highlightCheck: string[] = [];
    let hlSelector = data?.properties; // Which list to select highlights from
    if(data?.thumbnails?.length) hlSelector = data?.thumbnails;
    
    hlSelector?.forEach((item: any) => {
      if(
        highlightList.length < 3
        && item?.bg_image_url
      ) {
        if(!highlightCheck.includes(item.bg_image_url)) {
          highlightCheck.push(item.bg_image_url);
          highlightList.push({ id: item.id, url: linkUrl(process.env.NEXT_PUBLIC_ASSET_ROOT + '/files/attribute/', item.bg_image_url) ?? '', caption: item?.caption ?? '' });
        }
      }
    });

    setHighlights(highlightList);

  }, [ data?.properties, data?.thumbnails ]);
  
  return (
    <>
      <BuyInfoDialog ref={dialogRef} />

      <div className="bg-white rounded-sm">
        <div className="box-border  shadow-md rounded-xl border border-dashed border-gray-200 p-2 md:p-5 grid md:grid-cols-2 gap-4 md:gap-8">
          <div className="cols-span-1 ">
            <div
              className={`grid grid-cols-9 shadow-md box-border rounded-lg overflow-hidden relative ${data?.vertical_image_url && data?.horizontal_image_url ? '' : 'pr-[5px]'}`}
              style={{ backgroundColor: data.image_bg_color }}
            >
              {
                (data?.vertical_image_url && data?.horizontal_image_url) ? (
                  <div className="col-span-9 rounded-l-lg overflow-hidden relative">
                    <div className={`relative w-full aspect-video md:hidden`}>
                      <Image
                        src={linkUrl(process.env.NEXT_PUBLIC_ASSET_ROOT + '/assets/webgorsel/', data.horizontal_image_url) ?? ''}
                        alt={data.caption}
                        fill={true}
                        sizes="100%"
                        style={{ objectFit: 'cover', objectPosition: 'center' }}
                      />
                    </div>
                    <div className={`relative w-full hidden md:block aspect-square`}>
                      <Image
                        src={linkUrl(process.env.NEXT_PUBLIC_ASSET_ROOT + '/assets/webgorsel/', data.vertical_image_url) ?? ''}
                        alt={data.caption}
                        fill={true}
                        sizes="100%"
                        style={{ objectFit: 'cover', objectPosition: 'center' }}
                      />
                    </div>
                    <div className="absolute top-0 right-0 bottom-0 p-[5px] flex flex-col justify-between gap-[5px]">
                      {
                        highlights?.map((item: { id: number, url: string, caption: string }) => (
                          <div key={item.id} className={`relative flex justify-stretch items-stretch w-[90px] h-[60px] bg-[rgba(0,0,0,0.2)] rounded-[5px] p-1 z-[1]`}>
                            <div className={`relative grow`}>
                              <Image
                                src={item.url}
                                alt={item.caption}
                                fill={true}
                                sizes="100%"
                                style={{ objectFit: 'contain', objectPosition: 'center' }}
                              />
                            </div>
                          </div>
                        ))
                      }
                    </div>
                  </div>
                ) : (
                  <>
                    <div className="col-span-6 rounded-l-lg overflow-hidden relative">
                      <Image
                        src={`${process.env.NEXT_PUBLIC_ASSET_ROOT}/assets/webgorsel/${data.image_url}`}
                        alt={"Doping Hafıza"}
                        width={511}
                        height={431}
                      />
                    </div>
                    <div className="col-span-3 overflow-hidden rounded-r-lg p-1 relative flex flex-col items-end">
                      {
                        highlights.map((item: { id: number; url: string; caption: string }) => (
                          <div key={item.id} className="w-[90px] h-[60px] bg-black bg-opacity-20 rounded-[4px] mt-[5px] flex items-center justify-center">
                            <Image
                              src={item.url}
                              alt={"Doping Hafıza"}
                              width={85}
                              height={33}
                            />
                          </div>
                        ))
                      }
                    </div>
                  </>
                )
              }
            </div>
          </div>
          <div className="col-span-1">
            <LoadingLink href={formatUrl(`${packagesSlug}/${data.package_category?.slug}/${data.slug}`, true)} onClick={ handleProductClick } className="sm:text-[20px] font-bold hover:text-lava mb-5">
              {data.caption}
            </LoadingLink>
            <ul className="list-disc pl-5 -mb-2 text-sm">
              {
                (data?.card_props?.length ? data.card_props : data.properties)?.filter((item: Record<string,any>) => {
                  if(item?.dynamic_value) {
                    if(
                      !Object.keys(productDynamicValues).includes(item.dynamic_value)
                      || !data?.curriculums?.summary?.[productDynamicValues?.[item.dynamic_value]]
                    ) return false;
                  }

                  return data?.card_props?.length || (item?.view_type && item.view_type !== 'Detay');
                })?.slice(0, Number(process.env.NEXT_PUBLIC_PRODUCT_CARD_MAX_ROWS ?? 6))?.map((item: Record<string,any>) => {
                  let caption = item?.caption ?? '';
                  if(
                    item?.dynamic_value
                    && Object.keys(productDynamicValues).includes(item.dynamic_value)
                    && data?.curriculums?.summary?.[productDynamicValues?.[item.dynamic_value]]
                  ) {
                    let val = data.curriculums.summary?.[productDynamicValues[item.dynamic_value]] ?? 0;
                    if([ 'İçerik Süresi', 'İnteraktif İçerik Süresi' ].includes(item.dynamic_value)) {
                      val = formatNumber(durationMinutes(val as number)) + ' dk';
                    } else {
                      val = formatNumber(val as number);
                    }
                    
                    caption = val + (data?.card_props?.length ? ' ' + caption : '');
                  } else if(!data?.card_props?.length && item?.special_button === 'Şimdi Anladım') return (
                    <li key={item.id} className="font-bold">
                      <DPIcon icon="star" className="me-1" />
                      { item.caption } { item?.subcap }
                    </li>
                  );

                  if(!data?.card_props?.length) return (
                    <li key={item.id}>
                      { caption } { item?.subcap }
                    </li>
                  );

                  return (
                    <li key={item.id} className={item?.bold ? 'font-bold' : 'font-normal'}>
                      {
                        item?.icon ? (
                          <DPIcon icon={item.icon} className="me-1" />
                        ) : (<></>)
                      }
                      { caption }
                    </li>
                  );
                })
              }
            </ul>
            
            <div className="flex mt-7 gap-2 items-end mb-0">
              {
                data?.prevent_sales && data?.coming_soon ? (
                  <>
                    <div className="text-[24px] sm:text-2xl text-[#0b6ab2] font-bold">
                      { _e('Yakında Gelecek') }
                    </div>
                  </>
                ) : (
                  <>
                    <div className="text-[24px] sm:text-2xl text-[#0b6ab2] font-bold">
                      { formatNumber(data.price) } TL
                    </div>
                    <div className=" text-[#cc171b] line-through">
                      { data?.cancelled_price ? formatNumber(data.cancelled_price) + ' TL' : '' }
                    </div>
                  </>
                )
              }
            </div>
            <div className="flex items-start sm:items-center text-[#3EB657] mb-3">
              <span>
                {
                  data?.prevent_sales && data?.coming_soon ? (
                    <>
                      <DPIcon icon="warning" className="text-[#3eb657] mr-[5px]" />
                      <span dangerouslySetInnerHTML={{ __html: _e('<strong>:name</strong> paketimiz yakında satışa sunulacaktır.', { name: stripTags(data?.caption ?? '') }) }}></span>
                    </>
                  ) : (
                    <span className="font-bold">
                      <DPIcon icon="warning" className="text-[#3eb657] mr-[5px]" />
                      { _e('Peşin Fiyatına Vade Farksız') } <strong className="whitespace-nowrap">{ _e('Ayda :count TL', { count: formatNumber(data.price / 12) }) }</strong> { _e('Taksitle') }
                    </span>
                  )
                }
              </span>
            </div>

            {
              !(data?.prevent_sales && data?.coming_soon) ? (
                <>
                  <button
                    type="button"
                    className="py-[16px] px-4 rounded-md bg-[#0b6ab2] hover:bg-blue-600 text-white w-full font-bold "
                    data-ecommerce-id={getECommerceId(data) ?? '0'}
                    onClick={() => addToCart(data.external_id, getECommerceId(data) ?? '0', data.price ?? 0, data.caption ?? '', data?.package_category?.caption ?? '', router)}
                  >
                    { _e('Satın Al') }
                  </button>
                </>
              ) : (
                <>
                  <button
                    type="button"
                    className="py-[16px] px-4 rounded-md bg-[#0b6ab2] hover:bg-blue-600 text-white w-full font-bold "
                    data-ecommerce-id={getECommerceId(data) ?? '0'}
                    onClick={() => { dialogRef?.current?.open(stripTags(data?.caption ?? ''), stripTags(data?.caption ?? '')); }}
                  >
                    { _e('Bilgi Bırak') }
                  </button>
                </>
              )
            }
          </div>
        </div>
        <div className="flex items-center text-lava font-semibold justify-center pt-10">
          <LoadingLink href={`/${packagesSlug}`} className="flex items-center">
            <span className="text-lg">{ _e('Diğer paketleri incele') }</span>
            <DPIcon icon="arrow-circle-right" className="ml-[10px] text-[26px]" />
          </LoadingLink>
        </div>

        <JsonLd data={{
          "@context": "https://schema.org",
          "@type": "Product",
          "name": data.caption,
          "image": linkUrl(process.env.NEXT_PUBLIC_ASSET_ROOT + '/assets/webgorsel/', data.image_url),
          "description": insertENetValues(data?.content ?? '', formatENetValues({ ...(data?.curriculums?.credits ?? {}), ...{ Sezon: data.season, 'Paket Adı': data.caption } }), true),
          "brand": {
            "@type": "Brand",
            "name": "Doping Hafıza"
          },
          "offers": {
            "@type": "Offer",
            "priceCurrency": "TRY",
            "price": data.price,
            "url": formatUrl(`${packagesSlug}/${data.package_category?.slug}/${data.slug}`, true)
          }
        }} />

      </div>
    </>
  );
}
