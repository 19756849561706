"use client";

import DPIcon from "../DPIcon/DPIcon";
import { useEffect, useState } from "react";
import { Breadcrumb } from "@/types/page";
import JsonLd from "../JsonLd/JsonLd";
import LoadingLink from "../LoadingLink/LoadingLink";

export default function Breadcrumbs({ list, className }: {
  readonly list: Breadcrumb[],
  readonly className?: string,
}) {

  const [ isLoaded, setIsLoaded ] = useState(false);

  useEffect(() => {
    setIsLoaded(true);
  }, []);

  return (
    <div className={`${isLoaded ? 'opacity-100 transition-all duration-300' : 'opacity-0'} container relative flex items-center justify-start max-w-full overflow-auto text-xs sm:text-sm leading-relaxed !py-4 custom-scroll ${className ?? ''}`}>
      <LoadingLink href="/">
        <DPIcon icon="home" className="text-slate-500 hover:text-lava transition-all" />
        <DPIcon icon="arrow-right" className="mx-2 text-slate-500 hover:text-lava transition-all" />
      </LoadingLink>
      {
        list.map((item: Breadcrumb, index: number) => {
          return (
            <div key={item.id + '-' + item.slug} className="flex items-center whitespace-nowrap">
              {
                (() => {
                  if(index === list.length - 1) return (<span className="cursor-default text-charcoal">{ item.caption }</span>);

                  return (
                    <>
                      <LoadingLink href={item.slug} className="text-slate-500 hover:text-lava transition-all">
                        { item.caption }
                      </LoadingLink>
                      <DPIcon icon="arrow-right" className="mx-2 text-slate-500 hover:text-lava transition-all" />
                    </>
                  );
                })()
              }
            </div>
          );
        })
      }

      <JsonLd data={{
        "@context": "https://schema.org",
        "@type": "BreadcrumbList",
        "itemListElement": list?.map((item: Breadcrumb, index: number) => {
          return {
            "@type": "ListItem",
            "position": index + 1,
            "name": item.caption,
            "item": process.env.NEXT_PUBLIC_SITEMAP_BASE_URL + item.slug
          };
        })
      }} />
    </div>
  );
}
